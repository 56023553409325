<template>
  <div> 
        <IconTopTable />
        
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <tr v-for="(item, key) in data.data.data" :key="key" >                
                            
                            <td>
                                <label class="table-check">
                                    <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                    <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code" >
                                <td v-if="column.Status && column.code == 'ncpe_001'" :code="column.code">
                                    <span v-html="ema_id(item.links)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'ncpe_002'" :code="column.code">
                                    {{item.drug_id}}
                                </td>    
                                <!-- <td v-else-if="column.Status && column.code == 'ncpe_003'" :code="column.code">
                                    <span v-html="icon_link(item.drug_id,'ncpe')"></span>
                                </td>      -->
                                <td v-else-if="column.Status && column.code == 'ncpe_004'" :code="column.code">
                                    {{check_empty(item.category)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ncpe_005'" :code="column.code">
                                    {{check_empty(item.dci_new)}}
                                </td>  
                                <td v-else-if="column.Status && column.code == 'ncpe_039'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                </td>    
                                <td v-else-if="column.Status && column.code == 'ncpe_006'" :code="column.code">
                                    <span v-html="column_status(item)"></span>
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ncpe_007'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.ma_date))}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ncpe_008'" :code="column.code">                                 
                                    {{check_empty(DDMMMYY(item.ncpe.date))}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ncpe_009'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ncpe_010'" :code="column.code">
                                    {{check_empty(item.dci)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ncpe_011'" :code="column.code">
                                    {{check_empty(item.firm)}}
                                </td>       
                                <td v-else-if="column.Status && column.code == 'ncpe_012'" :code="column.code">                                
                                    <span v-html="check_empty(array(item.atc))"></span>
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ncpe_013'" :code="column.code">
                                    <span v-html="check_empty(array(item['indexation_'+$i18n.locale]))"  ></span>
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ncpe_014'" :code="column.code">
                                    {{check_empty(item.ncpe.rapid_review_outcome)}}                                
                                </td>  
                                <td v-else-if="column.Status && column.code == 'ncpe_015'" :code="column.code">
                                    <span :class="color_pharmacoeconomic_evaluation_outcome(item.ncpe.pharmacoeconomic_evaluation_outcome)">
                                        {{check_empty(item.ncpe.pharmacoeconomic_evaluation_outcome)}}
                                    </span>                                
                                </td>   
                                <td v-else-if="column.Status && column.code == 'ncpe_016'" :code="column.code">
                                    <span :class="color_type_limitation(item.prioritis_hta_index)">{{check_empty(item.prioritis_hta_index)}}</span>                                
                                </td>   
                                <template  v-else-if="column.Status && column.code == 'ncpe_017'" >                             
                                    <td  :key="column.code+'_018'" code="ncpe_018">
                                        <Readmore :longText="item.target_population" />
                                    </td>      
                                    <td :key="column.code+'_019'" code="ncpe_019">
                                        <Readmore :longText="item.prescription_and_delivery_conditions" />
                                    </td>      
                                    <td :key="column.code+'_020'" code="ncpe_020">
                                        <Readmore :longText="item.access_scheme" />
                                    </td>      
                                    <td :key="column.code+'_021'" code="ncpe_021">
                                        <Readmore :longText="item.outcome_level" />
                                    </td>      
                                </template>

                                <td v-else-if="column.Status && column.code == 'ncpe_022'" :code="column.code">                                
                                    <Readmore :longText="item.rationale_and_comments_html_en" />
                                </td>
                                <td v-else-if="column.Status && column.code == 'ncpe_023'" :code="column.code">                               
                                    <Readmore :longText="item.indication_en"/>  
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ncpe_024'" :code="column.code">                                
                                    <span v-if="$i18n.locale !== 'fr'" v-html="check_empty(array(item.classification_en))"  ></span>
                                    <span v-else v-html="check_empty(array(item.classification_fr))"></span>
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ncpe_025'" :code="column.code">
                                    {{check_empty_with_locale(item.mk_orphelin)}}
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ncpe_026'" :code="column.code">
                                    <span v-html="nct_number(item.nct_numbers)" @click="drug_id = item.drug_id"></span>
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ncpe_027'" :code="column.code">
                                    <span v-html="check_empty(treatment_line(item.treatment_line))"></span>
                                </td>                                      
                                <td v-else-if="column.Status && column.code == 'ncpe_028'" :code="column.code">
                                    {{check_empty(item.specificity)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ncpe_029'" :code="column.code">
                                    {{check_empty(item.administration)}}
                                </td>      
                                <template  v-else-if="column.Status && column.code == 'ncpe_030'">                
                                    <!-- data-model -->
                                    <td  :key="column.code+'_031'" code="ncpe_031">
                                        {{check_empty(item.hta_process_date)}} 
                                    </td>      
                                    <td  :key="column.code+'_032'" code="ncpe_032">
                                        <span v-html="hta_process_status(item,'hta2_')"></span>
                                    </td>      
                                    <td  :key="column.code+'_033'" code="ncpe_033">
                                        {{check_empty(DDMMMYY(item.rembursement_process_date))}} 
                                    </td>      
                                    <td   :key="column.code+'_034'" code="ncpe_034">
                                        <span v-html="rembursement_status(item.reimbursement_decision, 'rb2_')"></span>
                                    </td>      
                                </template>
                                <template  v-else-if="column.Status && column.code == 'ncpe_035'">   
                                    <td :key="column.code+'_036'" code="ncpe_036">
                                        <!-- modal_conclusion -->
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="hta_process_status_2(item)"></span>
                                    </td>      
                                    <td :key="column.code+'_037'" code="ncpe_037">                                  
                                        <span @data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="rembursement_status_2(item)"></span>
                                    </td>      
                                    <td :key="column.code+'_038'" code="ncpe_038">                          
                                        {{datediff(item.ma_date, item.rembursement_process_date)}}
                                    </td>  
                                </template>
                            </template>
                        </tr>

                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div> 
        <ModalHTAstatus :item="item_hta_status" /> 
        <ModalEssaisClinique :id="drug_id" agency="Ncpe" /> 
    </div>
</template>

<script>
import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import Readmore from '@/components/elements/readmore.vue';
import ModalHTAstatus from '@/components/result/modal_hta_status.vue'
import { isAdmin, column_status, hta_process_status, hta_process_status_2, rembursement_status, rembursement_status_2, 
        overview_regulatory_status, modifyPage, ema_id, icon_link, DDMMMYY, check_empty, nct_number, treatment_line,
        datediff, array, color_pharmacoeconomic_evaluation_outcome,color_type_limitation, check_empty_with_locale } from '@/utils'
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "Ncpe",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalHTAstatus,
        ModalEssaisClinique
    },
    data() {
        return {  
            item_hta_status : {},
            drug_id : 0 //for essais clinique popup
        }
    },
    computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }   
    }, 
    
    methods:{  
        isAdmin,        
        column_status,  
        hta_process_status,        
        hta_process_status_2,
        rembursement_status,
        rembursement_status_2,
        overview_regulatory_status,
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        array,
        color_pharmacoeconomic_evaluation_outcome,
        color_type_limitation,
        check_empty_with_locale,
        hta_status_modal(item){
            this.item_hta_status = item
        },
        modifyPage
    }
}
</script>

<style scoped>
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>